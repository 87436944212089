// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

body,
html {
	background-color: #fff;
}

$enable-negative-margins: true;
$offcanvas-horizontal-width: 300px;
$offcanvas-border-width: 0px;
$offcanvas-backdrop-bg: white;

$spacer: 1rem;
$spacers: (
	0: 0,
	1: $spacer * 0.25,
	2: $spacer * 0.5,
	3: $spacer,
	4: $spacer * 1.5,
	5: $spacer * 3,
	50: 50px,
	70: 70px,
	100: 100px,
	150: 150px,
	200: 200px,
	280: 280px,
	300: 300px,
	400: 400px
);

// This is to remove weird blue border on safari after clicks
:focus {
	outline: none;
	outline: none -webkit-focus-ring-color;
}

// Fonts
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
	"Helvetica Neue", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.4;
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-semibold: 600;
$font-weight-bold: 800;
$form-label-color: #3d4850 !important;
$form-label-font-weight: 400 !important;
$form-label-margin-bottom: 0rem !important;

// Colors
$primary: #3D4850;
$secondary: #cfd6da;
$warning: #f7c188;
$danger: #e68484;
$info: #b9d5ee;
$success: #a8cf97;
$dark: #303940;
$light: #f2f3f4;

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"warning": $warning,
	"danger": $danger,
	"info": $info,
	"success": $success,
	"dark": $dark,
	"light": $light,
	"green": #329932,
	"red": #ff2800,
	"blue": #0091c2,
	"orange": #ffb300,
	"yellow": #ffff32
);

$ltb-colors: (
	"ltb-light": #ebf3fa,
	"ltb-dark": #001224,
	"body-dark": #8a9196,
	"body-light": #c1ccd5,
	"even": #F9F9FA,
	"odd": #FDFDFD,
	"twitter": #1da1f2,
	"whatsapp": #25d366,
	"googleplus": #ea4335,
	"instagram": #c32aa3,
	"linkedin": #0a66c2,
	"youtube": #ff0000,
	"facebook": #1877f2,
	"tiktok": #010101,
	"pinterest": #bd081c,
	"snapchat": #fffc00,
	"twitch": #9146ff,
	"discord": #5865f2,
	"Yelp": #d32323
);

$theme-colors: map-merge($theme-colors, $ltb-colors);

// Modals
$modal-backdrop-bg: #fff;
$modal-backdrop-opacity: 0.5;

// Cards
$card-group-margin: 0rem !important;
$card-bg: #fff !important;

// START - Menu offcanvas
.offcanvas-body a {
	font-size: 1rem;
}
$enable-negative-margins: true;
$offcanvas-horizontal-width: 100%;
$offcanvas-vertical-height: 100%;
$offcanvas-padding-x: 0px;
$offcanvas-border-width: 0px;
$offcanvas-bg-color: $dark;
$offcanvas-backdrop-bg: $dark;
$offcanvas-backdrop-opacity: 0;
// END - Menu offcanvas

// Tables
$table-hover-bg: #ebf3fa;
$table-border-color: #f0f6fb;
$table-color: $dark;
$table-striped-bg-factor: 0.03;
$table-striped-bg: rgba($dark, $table-striped-bg-factor);

.hover-opacity:hover {
	opacity: 0.5;
}

$dropdown-bg: $dark;
$dropdown-color: $light;
$dropdown-link-color: $light;
$dropdown-border-color: $dark;
$dropdown-link-hover-bg: #ffb300;
$dropdown-link-hover-color: $dark;
$dropdown-link-active-color: $dark;
$dropdown-divider-bg: $dark;
$dropdown-dark-divider-bg: $dark;
$dropdown-item-padding-x: 0.2rem;

$nav-link-hover-color: $dark;
.nav-link:focus,
.nav-link:hover {
	color: $secondary !important;
}

$navbar-light-color: $dark;
$navbar-dark-color: $dark;
$navbar-light-hover-color: #0091c2;

// RESPONSIVE NAVBAR CUSTOMIZATION - START
@media only screen and (max-width: 767.98px) {
	.navbar-ltb {
		background: $light; //rgb(244,244,244);
		background: linear-gradient(180deg, rgb(18, 18, 18) 20%, rgba(255, 255, 255, 1) 100%);
		box-shadow: 0 3rem 3rem -2rem rgba(var(--bs-body-color-rgb), 0.175) !important;
	}
	.dropdown-menu {
		--bs-dropdown-bg: transparent !important;
		--bs-dropdown-border-color: transparent !important;
	}

	.nav-link {
		font-size: 2rem !important;
	}

	.dropdown-item {
		font-size: 1.5rem !important;
	}
}

.navbar-toggler {
	width: 40px;
	height: 20px;
	position: relative;
	transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
	outline: none !important;
	box-shadow: none !important;
	border: 0 !important;
}

.navbar-toggler span {
	margin: 0;
	padding: 0;
}

.toggler-icon {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: $primary;
	border-radius: 1px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: 0.25s ease-in-out;
}

.toggler-icon-close {
	display: block;
	position: absolute;
	height: 3px;
	width: 100%;
	background: #ffffff;
	border-radius: 1px;
	opacity: 1;
	left: 0;
	transform: rotate(0deg);
	transition: 0.5s ease-in-out;
}

.middle-bar {
	margin-top: 0px;
}

// When navigation is clicked
.navbar-toggler .top-bar {
	width: 30px;
	margin-top: 0px;
	transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
	opacity: 0;
	filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
	width: 35px;
	margin: 0px;
	transform: rotate(-135deg);
}

// State when navbar is collapssed
.navbar-toggler.collapsed .top-bar {
	width: 30px;
	margin-top: -20px;
	transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
	width: 20px;
	opacity: 1;
	filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
	width: 35px;
	margin-top: 20px;
	transform: rotate(0deg);
}
// RESPONSIVE NAVBAR CUSTOMIZATION - END

/* Ensure the sticky-top navbar has a higher z-index */
.navbar.sticky-top {
	z-index: 1020 !important; /* Bootstrap’s default z-index for navbar is 1030 */
}

/* Additional styling to ensure dropdowns appear on top of other content */
.navbar .dropdown-menu {
	z-index: 1030 !important;
}

// CHOICES.JS - START
.choices__inner {
	background-color: #fff !important;
	border: var(--bs-border-width) solid var(--bs-border-color) !important;
	border-radius: var(--bs-border-radius) !important;
	padding: 0.375rem 0.75rem !important;
	font-weight: 400 !important;
	min-height: auto !important;
}

.choices__list--single {
	padding: 0.2rem 0.75rem !important;
}
// CHOICES.JS - END

input[type="number"] {
	-moz-appearance: textfield; /* Removes the spinner in Firefox */
}

/* To remove the spinner from Chrome, Safari, and newer versions of Opera, 
 use the ::-webkit-inner-spin-button and ::-webkit-outer-spin-button pseudo-elements */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0; /* Removes the margin in Chrome, Safari, Opera */
}

.htmx-indicator{
    display:none;
}
.htmx-request .htmx-indicator{
    display:inline;
}
.htmx-request.htmx-indicator{
    display:inline;
}

#processingIndicator {
	position: fixed;
	display: none;
	/* Hidden by default */
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.8);
	/* Black background with opacity */
	z-index: 5;
	/* Sit on top */
	cursor: progress;
}

header {
	font-weight: 600 !important;
	font-size: calc(1.275rem + 0.3vw);
	margin-bottom: 0.5rem;
	margin-top: 1rem;
}

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

@import "../node_modules/bootstrap/scss/bootstrap";
